<template>
    <div class="row">
        <div class="col-md-6">
            <DatosEmpresa-datos-datos :empresa="empresa" />
        </div>
        <div class="col-md-5">
            <DatosEmpresa-datos-contabilidad :empresa="empresa" />
            <DatosEmpresa-datos-facturacion :empresa="empresa" />
        </div>
    </div>
</template>
<script>
import DatosEmpresaDatosDatos from './DatosEmpresaDatosDatos.vue'
import DatosEmpresaDatosContabilidad from './DatosEmpresaDatosContabilidad.vue'
import DatosEmpresaDatosFacturacion from './DatosEmpresaDatosFacturacion.vue'
export default {
    props: ['empresa', 'id'],
    components: {
        'DatosEmpresa-datos-datos': DatosEmpresaDatosDatos,
        'DatosEmpresa-datos-contabilidad': DatosEmpresaDatosContabilidad,
        'DatosEmpresa-datos-facturacion': DatosEmpresaDatosFacturacion,
    },
    data() {
        return {}
    }
}
</script>